.presidentCard {
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px;
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    transition: visibility 0.3s ease;
}
.presidentCard:hover {
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    cursor: auto;
}
.presidentImage {
    overflow: hidden;
    padding-top: 1rem;
    background-color: #fcfdfc;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.presidentImage img {
    object-fit: cover;
    object-position: top;
    height: 13rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
.presidentCard .slaviaLogo {
    position: absolute;
    top: 1rem;
    right: calc(1rem + 15px);
    width: 72px;
    height: 72px;
}
.presidentCard .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.presidentCardDetails {
    padding: 0.5rem 0.25rem;
    padding-bottom: 1rem;
    background-color: #343a40 !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.presidentCardSurname {
    color: #c8bba6;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
}
.presidentCardPosition {
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
}