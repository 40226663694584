.navbar-brand {
    position: absolute;
    top: -2rem;
}
#main-navbar {
    padding-top: 3rem;
    padding-left: 1.5rem;
}
#main-navbar-nav {
    padding-left: calc(1rem + 92px);
    text-transform: uppercase;
}
#main-navbar-nav .nav-link.active {
    font-weight: 600;
    color: #564A31 !important;
    transition: all ease;
}
#main-navbar-nav .nav-item.active .nav-link {
    font-weight: 600;
    color: #564A31 !important;
    transition: all ease;
}
#main-navbar-nav .dropdown-item.active, #main-navbar-nav .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #b2adac;
}
#main-navbar-nav .dropdown-menu {
    background-color: #f8f9fa!important;
    border-color: transparent;
}
#main-navbar-nav .nav-link:hover {
    text-shadow: 0 0 0 #333;
    color: #564A31 !important;
}
.navbar-toggler {
    margin-bottom: 20px;
    margin-top: -20px;
}
.navbar-toggler:focus {
    box-shadow: none;
}
#main-navbar-nav .dropdown-header {
    font-size: 0.75rem !important;
    margin-top: 0.45rem;
    padding-bottom: 0.1rem !important;
    color: #564A31;
    font-weight: 700;
}
#main-navbar-nav .dropdown-header:first-child {
    margin-top: 0rem !important;
}
#main-navbar-nav .pad-left {
    padding-left: 0.75rem;
}

@media (max-width: 991px) {
    .navbar-brand {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    #main-navbar-nav {
        padding-left: 0;
    }
    .no-scroll {
        margin: 0;
        height: 100%;
        overflow: hidden;
    }
    .scroll {
        height: 100vh;
        overflow: scroll;
        background-color: #fefefe;
        transition: height 0.2s;
    }
}
@media (min-width: 992px) {
    #main-navbar-nav .nav-link {
        border-bottom: 2px solid transparent !important;
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
    }
    #main-navbar-nav .nav-link.active {
        font-weight: 600;
        color: #564A31 !important;
        border-bottom: 3px solid #b2adac !important;
        transition: all ease;
    }
    #main-navbar-nav .nav-item.active .nav-link {
        font-weight: 600;
        color: #564A31 !important;
        border-bottom: 3px solid #b2adac !important;
        transition: all ease;
    }
    #main-navbar-nav .dropdown-menu {
        -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 7%); 
        box-shadow: 0px 15px 10px 3px rgb(50 50 93 / 10%);
    }
}