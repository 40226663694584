.hall-of-fame h2 {
    font-size: 1.75rem;
    line-height: 2rem;
    text-transform: uppercase;
}
.hall-of-fame hr {
    margin-top: 0.25rem;
    margin-bottom: 0;
    border-top: 3px solid #c7b184;
}
.trophies-section {
    background-color: #f8f9fa;
}
.trophy-wrapper {
    width: 100%;
}
:root {
    --swiper-theme-color: #A55858;
    --swiper-navigation-color: #c7b184;
}
.trophy {
    position: relative;
    height: 20rem;
    background: #fff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
}
.trophy-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.trophy .trophy-content {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 2rem;
    border-radius: 4px;
    width: 80%;
    z-index: 1000;
}
.trophy .trophy-content h4 {
    margin-bottom: 0;
}
.trophy .trophy-content .btn {
    width: 100%;
}
.trophy .icon, .trophy-modal .icon {
    font-size: 16rem;
    color: rgba(0, 0, 0, 0.5);
}
.trophy img, .trophy-modal img {
    height: 17rem;
}
.trophy-modal p {
    margin-top: 1.25rem;
    font-size: 1.4rem;
}
.trophy .placeholder, .trophy-modal .placeholder {
    filter: opacity(0.2);
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    padding-top: 1rem;
    padding-bottom: calc(24px + 1rem);
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.swiper-slide img {
    display: block;
    object-fit: cover;
}