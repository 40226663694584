.article-back {
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.article-full .text-muted {
    display: block;
    margin-bottom: 1.75rem;
    font-size: 1.1rem;
}
.article-full h1 {
    margin-bottom: 0.5rem !important;
    font-size: 2.25rem !important;
}
.social {
    margin: auto;
    margin-bottom: 2rem;
    width: 48px;
}
.social > *:hover {
    opacity: 0.5;
}
.social::before {
    content: '';
    height: 2px;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    position: absolute;
    bottom: calc(2rem + 24px);
    left: 0;
    background-color: #e6e6e6;
    z-index: -1;
}

.article-content {
    font-size: 1.125rem;
    line-height: 1.6;
}
.article-content p {
    margin-bottom: 1.4rem;
}

@media (min-width: 768px) {
    .article-full h1 {
        font-size: 3rem !important;
    }
}
@media (min-width: 992px) {
    .article-full {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
    .article-back {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
    .article-full h1 {
        font-size: 3.25rem !important;
    }
    .article-full p {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
@media (min-width: 1200px) {
    .article-full h1 {
        font-size: 4rem !important;
    }
}