#home-header-line {
    position: relative;
    background: rgb(229,224,218);
    background-position: center top;
    background-size: cover;
    padding: 5rem 0;
    -webkit-mask: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
#home {
    margin-bottom: 3rem;
}
#home h2 {
    font-weight: 700;
    color: #343a40;
}
#home .btn {
    padding-left: 2rem;
    padding-right: 2rem;
}
#home #home-header {
    position: relative;
    height: calc(100vh - 142.4px - 1.5rem - 220px);
}

#home #home-title {
    margin-top: -5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    transform: translate(-50%, -50%);
}

#home #home-title .title {
    position: relative;
    background: repeat;
    background-position: 40% 50%;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 78px;
    font-weight: 800;
    line-height: 78px;
    text-align: center;
    letter-spacing: -8px;
    text-transform: uppercase;
}
#home #home-title .subtitle {
    font-size: 20px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    padding-top: 10px;
    letter-spacing: 0.35rem;
}

#team-photo {
    position: relative;
}
#team-photo .ghost-image {
    position: relative;
    max-height: 350px;
    object-fit: contain;
    -webkit-filter: drop-shadow(0px 5px 10px #c8c9ca);
    filter: drop-shadow(0px 5px 10px #c8c9ca);
    -webkit-transition: src 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 0;
}
#team-photo img {
    position: absolute;
    bottom: 0;
    max-height: 350px;
    object-fit: contain;
    -webkit-filter: drop-shadow(0px 5px 10px #c8c9ca);
    filter: drop-shadow(0px 5px 10px #c8c9ca);
    -webkit-transition: src 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
#home-sponsors a {
    color: initial;
}
#home-sponsors a:hover {
    text-decoration: none !important;
    color: #000;
}
#home-sponsors a:hover img {
    filter: grayscale(1) opacity(0.75) brightness(1.25);
}

#home-news {
    position: relative;
}

#home .match {
    border: 5px solid lightgray;
    border-radius: 5px;
    padding-left: 8px !important;
    padding-right: 8px !important;
}


.home-sponsor {
    height: 8rem;
    margin-bottom: 2rem;
    padding: 0.5rem !important;
}
.home-sponsor .image {
    position: relative;
    height: 8rem;
    width: 100%;
}
.home-sponsor img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: 10rem;
    object-fit: scale-down;
}

@media (min-width: 576px) {
    #home #home-title .title {
        font-size: 96px;
        line-height: 96px;
        background-position: 40% 50%;
    }
    #home #home-header {
        position: relative;
        height: calc(100vh - 142.4px - 1.5rem - 200px);
    }
    .home-sponsor, .home-sponsor .image {
        height: 12rem;
    }
}
@media (min-width: 768px) {
    #home #home-title .title {
        font-size: 96px;
        line-height: 96px;
        background-position: 40% 50%;
    }
    #home .match .team {
        font-size: 1rem !important;
    }
    .home-sponsor, .home-sponsor .image {
        height: 9rem;
    }
}
@media (min-width: 992px) {
    #home #home-title .title {
        font-size: 96px;
        line-height: 96px;
        margin-top: -1rem;
        background-position: 40% 50%;
    }
    #home .match .team {
        font-size: 1rem !important;
    }
    .home-sponsor, .home-sponsor .image {
        height: 12rem;
    }
}
@media (min-width: 1200px) {
    #home #home-header {
        height: calc(100vh - 145.4px - 10rem);
    }
    #home #home-title .title {
        font-size: 120px;
        line-height: 120px;
        background-position: 40% 50%;
    }
    #home .match .team {
        font-size: 1.1rem !important;
    }
}