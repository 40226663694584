.timeline--wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
}
.timeline {
    margin: 0;
    max-width: 100%;
}
.timeline h1 {
    margin-top: -0.5rem !important;
    font-weight: 700 !important;
}
.timeline h2 {
    font-size: 1.5rem !important;
}
.timeline p {
    text-align: justify !important;
}
.timeline:before {
    width: 2px;
    background-color: #ced4da;
    border-width: 0;
}
.timeline-item-date {
    background-color: transparent;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    top: 2px;
    padding: 0;
}
.timeline-item-dateinner {
    background-color: transparent;
    color: #212529;
    text-indent: 0;
    text-align: right;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    line-height: 32px;
    font-size: 1.75rem;
}
.entry .title:before {
    top: 10px;
    color: #343a40;
    right: auto;
    left: 1px;
}

@media (min-width: 267px) {
    .entry .title:before {
        right: auto;
        left: 2px;
    }
}
@media (min-width: 347px) {
    .entry .title:before {
        right: auto;
        left: 2.5px;
    }
}
@media (min-width: 387px) {
    .entry .title:before {
        right: auto;
        left: 3px;
    }
}
@media (min-width: 508px) {
    .entry .title:before {
        right: auto;
        left: 4px;
    }
}
@media (min-width: 768px) {
    .entry .title:before {
        right: -8px;
        left: auto;
    }
}
@media (min-width: 992px) {
    .entry .title:before {
        right: -6px;
        left: auto;
    }
}
@media (min-width: 1200px) {
    .entry .title:before {
        right: -4px;
        left: auto;
    }
}

@media (max-width: 768px) {
    .timeline-item-dateinner {
        text-align: left;
    }
    .entry .title {
        width: 90%;
    }
}