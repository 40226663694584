.coachCard {
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px;
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    transition: visibility 0.3s ease;
}
.coachCard a {
    color: #212529 !important;
    text-decoration: none;
}
.coachCard a:hover {
    color: #212529 !important;
    text-decoration: none;
}
.coachCard:hover {
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%);
    cursor: pointer;
}
.coachImage {
    overflow: hidden;
    margin-top: 1rem;
}
.coachImage img {
    object-fit: cover;
    object-position: top;
    height: 13rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
.coachCard .slaviaLogo {
    position: absolute;
    top: 1rem;
    right: calc(1rem + 15px);
    width: 72px;
    height: 72px;
}
.coachCard .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.coachCardDetails {
    padding: 0.5rem 0.25rem;
    padding-bottom: 1rem;
    background-color: rgb(250 250 250);
}
.coachCardSurname {
    color: #a38c69;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
}
.coachCardPosition {
    text-transform: uppercase;
    font-weight: 500;
}