.playerCard {
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px;
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    transition: visibility 0.3s ease;
}
.filterList {
    margin-top: 1rem;
}
.filterList .filterListItem {
    transition: opacity 1s ease-out;
}
.playerCard a {
    color: #212529 !important;
    text-decoration: none;
}
.playerCard a:hover {
    color: #212529 !important;
    text-decoration: none;
}
.playerCard:hover {
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%);
    cursor: pointer;
}
.playerCard:hover .playerNumber {
    color: #564A31;
}
.playerNumber {
    position: absolute;
    top: 1rem;
    left: calc(1rem + 15px);
    color: #a38c69;
    font-size: 3.5rem;
    font-weight: 900;
    text-shadow: 0px 2px 3px rgb(50 50 93 / 24%);
    width: 4rem;
}
.playerNumber span {
    display: block;
    text-align: center;
}
.playerImage {
    overflow: hidden;
    margin-top: 1rem;
}
.playerImage img {
    object-fit: cover;
    object-position: top;
    height: 13rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    transition: transform .2s;
}
.playerCard:hover .playerImage img {
    transform: scale(1.1);
}
.playerCard .slaviaLogo {
    position: absolute;
    top: 1rem;
    right: calc(1rem + 15px);
    width: 72px;
    height: 72px;
}
.playerCard .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.playerCardDetails {
    padding: 0.5rem 0.25rem;
    padding-bottom: 1rem;
    background-color: rgb(250 250 250);
}
.playerCardSurname {
    color: #a38c69;
    font-weight: 700;
    text-transform: uppercase;
}
.playerCardPosition {
    text-transform: uppercase;
    font-weight: 500;
}
.playerCardInfoRight {
    line-height: 2;
}
.playerStat {
    font-weight: 600;
    padding-left: 0 !important;
}