.coach-details-content {
    margin-top: 1rem;
    word-wrap: break-word;
}
.coach-details .slaviaLogo {
    position: absolute;
    top: 10px;
    right: 0;
    right: calc(1rem + 15px);
    width: 94px;
    height: 94px;
    z-index: 1000;
}
.coach-details .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.coach-header .details {
    position: absolute;
    top: 0;
    margin-left: 5rem;
    height: 100%;
    width: 50%;
    z-index: 0;
}
.first-name {
    font-size: 2.25rem;
}
.last-name {
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
}
.position {
    font-size: 1.75rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #A55858;
}
.coach-image {
    height: 400px;
    border: 1px solid #aaa;
    background-color: #f9f9f9;
    object-fit: cover;
    object-position: top;
}
.coach-details {
    margin-bottom: 2rem;
}
.coach-details .section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2rem;
}
.coach-details .details-title {
    font-size: 1rem;
}
.coach-details .details-value {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.coach-details hr {
    margin-top: 0.25rem;
    border-top: 3px solid #c7b184;
}
.vertical-space {
    height: 1.25rem;
}
.vertical-space-lg {
    height: 0.5rem;
}

@media (max-width: 992px) {
    .coach-image {
        height: 350px;
        margin-right: 3rem;
    }
}
@media (max-width: 768px) {
    .coach-image {
        height: 300px;
        margin-right: 0;
    }
}
@media (max-width: 600px) {
    .coach-image {
        height: 300px;
        margin-right: -1rem;
    }
    .first-name {
        font-size: 2rem;
        line-height: 2rem;
        margin-top: 0.25rem;
    }
    .last-name {
        font-size: 2.75rem;
        line-height: 2.75rem;
    }
    .position {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

@media (min-width: 576px) {
    .coach-details .slaviaLogo {
        width: 94px;
        height: 94px;
    }
}
@media (min-width: 768px) {
    .coach-details .slaviaLogo {
        width: 94px;
        height: 94px;
        top: -2rem;
    }
}
@media (min-width: 992px) {
    .coach-details .slaviaLogo {
        width: 128px;
        height: 128px;
    }
}
@media (min-width: 1200px) {
    .coach-details .slaviaLogo {
        width: 148px;
        height: 148px;
    }
}