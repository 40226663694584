.sponsors {
    margin-top: 3rem;
}
.sponsors:last-child {
    margin-bottom: 5rem;
}
.sponsors a {
    color: initial;
}
.sponsors a:hover {
    text-decoration: none !important;
    color: #000;
}
.sponsors a:hover .title span {
    text-decoration: underline !important;
}
.sponsors a:hover .title b {
    padding-left: 0.85rem;
}
.sponsor {
    height: 16.25rem;
    margin-bottom: 2rem;
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px;
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
}
.sponsor .image {
    position: relative;
    height: 12rem;
    width: 100%;
}
.sponsor img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: 10rem;
    object-fit: scale-down;
}
.sponsor .title {
    height: 4.25rem;
    padding-left: 1.15rem;
    padding-right: 1.15rem;
}
.sponsor .title h4 {
    margin-bottom: 0.1rem;
}
.sponsor .title span {
    color: #0056b3;
}
.sponsor .title b {
    color: #0056b3;
    padding-left: 0.5rem;
    font-size: 1.15rem;
    transition: all 0.25s ease;
}