.calendar {
    margin-bottom: 2rem;
}
.calendar-filter {
    margin-top: 1rem;
}
.slavia-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #e5e0da;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 0.5rem;
    border-radius: 6px;
}

.tournaments {
    margin-top: 1rem;
}

.table {
    margin-bottom: 2rem !important;
}
.table thead {
    background-color: #343a40;
    color: white;
    font-size: 1rem;
    font-weight: 400 !important;
}
.table th {
    padding: 0.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.table td {
    padding: 0 !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.table a {
    color: #212529;
}
.table a div {
    width: 100%;
    padding-bottom: .25rem;
    padding-top: .25rem;
}
.no-results {
    margin-top: 2rem;
}
.phase {
    margin-top: 1rem;
}
.phase h4 {
    margin-bottom: 2rem;
}
.match {
    padding: 0.5rem 0 !important;
}
.match .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.match .team {
    font-size: 1.25rem;
    font-weight: 600;
    display: inline-block;
}
.match .result {
    padding: 0 1.5rem;
    font-size: 1.25rem;
}
.match .game {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.match .result .res {
    border: 1px solid #6c757d;
    padding: 0 0.5rem;
    width: 2rem;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    font-weight: 700;
}
.match .bigger-res {
    font-weight: 500;
}
.match .result .bigger-res {
    color: red;
}
.btn-stats {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}
.stats-col {
    justify-content: center !important;
}
.match .team {
    width: calc((100% - 8rem) / 2);
}
.match .time-remaining {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
}
.match .res-right {
    text-align: right;
}
.match .res-left {
    text-align: left;
}

@media (min-width: 992px) {
    .match .container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .stats-col {
        justify-content: flex-end !important;
    }
}

@media (max-width: 991px) {
    .round td {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

@media (max-width: 767px) {
    .match-location {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .match-date-col {
        margin-bottom: 0.75rem !important;
    }
    .match-location-col {
        justify-content: flex-end !important;
        margin-bottom: 0.75rem !important;
    }
    .match .result {
        padding: 0 1rem;
    }
    .match .team {
        width: calc((100% - 7rem) / 2);
        padding-left: 5px;
        padding-right: 5px;
    }
    .stats-col {
        margin-top: 0.75rem !important;
    }
    .match .time-remaining {
        width: 7rem;
    }
    .match .team {
        font-size: 1rem;
    }
}