@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);

.navbar-brand {
    position: absolute;
    top: -2rem;
}
#main-navbar {
    padding-top: 3rem;
    padding-left: 1.5rem;
}
#main-navbar-nav {
    padding-left: calc(1rem + 92px);
    text-transform: uppercase;
}
#main-navbar-nav .nav-link.active {
    font-weight: 600;
    color: #564A31 !important;
    transition: all ease;
}
#main-navbar-nav .nav-item.active .nav-link {
    font-weight: 600;
    color: #564A31 !important;
    transition: all ease;
}
#main-navbar-nav .dropdown-item.active, #main-navbar-nav .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #b2adac;
}
#main-navbar-nav .dropdown-menu {
    background-color: #f8f9fa!important;
    border-color: transparent;
}
#main-navbar-nav .nav-link:hover {
    text-shadow: 0 0 0 #333;
    color: #564A31 !important;
}
.navbar-toggler {
    margin-bottom: 20px;
    margin-top: -20px;
}
.navbar-toggler:focus {
    box-shadow: none;
}
#main-navbar-nav .dropdown-header {
    font-size: 0.75rem !important;
    margin-top: 0.45rem;
    padding-bottom: 0.1rem !important;
    color: #564A31;
    font-weight: 700;
}
#main-navbar-nav .dropdown-header:first-child {
    margin-top: 0rem !important;
}
#main-navbar-nav .pad-left {
    padding-left: 0.75rem;
}

@media (max-width: 991px) {
    .navbar-brand {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    #main-navbar-nav {
        padding-left: 0;
    }
    .no-scroll {
        margin: 0;
        height: 100%;
        overflow: hidden;
    }
    .scroll {
        height: 100vh;
        overflow: scroll;
        background-color: #fefefe;
        transition: height 0.2s;
    }
}
@media (min-width: 992px) {
    #main-navbar-nav .nav-link {
        border-bottom: 2px solid transparent !important;
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
    }
    #main-navbar-nav .nav-link.active {
        font-weight: 600;
        color: #564A31 !important;
        border-bottom: 3px solid #b2adac !important;
        transition: all ease;
    }
    #main-navbar-nav .nav-item.active .nav-link {
        font-weight: 600;
        color: #564A31 !important;
        border-bottom: 3px solid #b2adac !important;
        transition: all ease;
    }
    #main-navbar-nav .dropdown-menu { 
        box-shadow: 0px 15px 10px 3px rgb(50 50 93 / 10%);
    }
}
#home-header-line {
    position: relative;
    background: rgb(229,224,218);
    background-position: center top;
    background-size: cover;
    padding: 5rem 0;
    -webkit-mask: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
            mask: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}
#home {
    margin-bottom: 3rem;
}
#home h2 {
    font-weight: 700;
    color: #343a40;
}
#home .btn {
    padding-left: 2rem;
    padding-right: 2rem;
}
#home #home-header {
    position: relative;
    height: calc(100vh - 142.4px - 1.5rem - 220px);
}

#home #home-title {
    margin-top: -5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

#home #home-title .title {
    position: relative;
    background: repeat;
    background-position: 40% 50%;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 78px;
    font-weight: 800;
    line-height: 78px;
    text-align: center;
    letter-spacing: -8px;
    text-transform: uppercase;
}
#home #home-title .subtitle {
    font-size: 20px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    padding-top: 10px;
    letter-spacing: 0.35rem;
}

#team-photo {
    position: relative;
}
#team-photo .ghost-image {
    position: relative;
    max-height: 350px;
    object-fit: contain;
    -webkit-filter: drop-shadow(0px 5px 10px #c8c9ca);
    filter: drop-shadow(0px 5px 10px #c8c9ca);
    transition: all 0.2s ease-in-out;
    opacity: 0;
}
#team-photo img {
    position: absolute;
    bottom: 0;
    max-height: 350px;
    object-fit: contain;
    -webkit-filter: drop-shadow(0px 5px 10px #c8c9ca);
    filter: drop-shadow(0px 5px 10px #c8c9ca);
    transition: all 0.2s ease-in-out;
}
#home-sponsors a {
    color: #000;
    color: initial;
}
#home-sponsors a:hover {
    text-decoration: none !important;
    color: #000;
}
#home-sponsors a:hover img {
    -webkit-filter: grayscale(1) opacity(0.75) brightness(1.25);
            filter: grayscale(1) opacity(0.75) brightness(1.25);
}

#home-news {
    position: relative;
}

#home .match {
    border: 5px solid lightgray;
    border-radius: 5px;
    padding-left: 8px !important;
    padding-right: 8px !important;
}


.home-sponsor {
    height: 8rem;
    margin-bottom: 2rem;
    padding: 0.5rem !important;
}
.home-sponsor .image {
    position: relative;
    height: 8rem;
    width: 100%;
}
.home-sponsor img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%;
    height: 10rem;
    object-fit: scale-down;
}

@media (min-width: 576px) {
    #home #home-title .title {
        font-size: 96px;
        line-height: 96px;
        background-position: 40% 50%;
    }
    #home #home-header {
        position: relative;
        height: calc(100vh - 142.4px - 1.5rem - 200px);
    }
    .home-sponsor, .home-sponsor .image {
        height: 12rem;
    }
}
@media (min-width: 768px) {
    #home #home-title .title {
        font-size: 96px;
        line-height: 96px;
        background-position: 40% 50%;
    }
    #home .match .team {
        font-size: 1rem !important;
    }
    .home-sponsor, .home-sponsor .image {
        height: 9rem;
    }
}
@media (min-width: 992px) {
    #home #home-title .title {
        font-size: 96px;
        line-height: 96px;
        margin-top: -1rem;
        background-position: 40% 50%;
    }
    #home .match .team {
        font-size: 1rem !important;
    }
    .home-sponsor, .home-sponsor .image {
        height: 12rem;
    }
}
@media (min-width: 1200px) {
    #home #home-header {
        height: calc(100vh - 145.4px - 10rem);
    }
    #home #home-title .title {
        font-size: 120px;
        line-height: 120px;
        background-position: 40% 50%;
    }
    #home .match .team {
        font-size: 1.1rem !important;
    }
}
.article {
    width: 100%;
    margin-bottom: 3.5rem;
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px; 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
}
.article a, .article a:hover {
    color: inherit;
    text-decoration: none;
}
.article:hover { 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%);
    cursor: pointer;
}
.article:hover img {
    -webkit-filter: opacity(0.9);
            filter: opacity(0.9);
}
.article .image img {
    width: 100%;
    height: 12rem;
    border-radius: 4px 4px 0 0;
    object-fit: cover;
    object-position: top;
}
.article .image, .article .image img {
    width: 100%;
}
.article .underline {
    height: 3px;
    width: 100%;
    background: linear-gradient(to right, #343a40 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .25s ease-out;
}
.article:hover .underline {
    background-position: left bottom;
}
.article .body {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.article h4 {
    margin-top: 0.25rem;
    font-weight: 700;
}
.article .text-muted {
    font-size: 0.9rem;
    display: block;
    margin-top: -0.2rem;
}
.article p {
    display: block;
    margin-top: 0.25rem;
    padding-left: 0.5rem;
}
.timeline--wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
}
.timeline {
    margin: 0;
    max-width: 100%;
}
.timeline h1 {
    margin-top: -0.5rem !important;
    font-weight: 700 !important;
}
.timeline h2 {
    font-size: 1.5rem !important;
}
.timeline p {
    text-align: justify !important;
}
.timeline:before {
    width: 2px;
    background-color: #ced4da;
    border-width: 0;
}
.timeline-item-date {
    background-color: transparent;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    top: 2px;
    padding: 0;
}
.timeline-item-dateinner {
    background-color: transparent;
    color: #212529;
    text-indent: 0;
    text-align: right;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    line-height: 32px;
    font-size: 1.75rem;
}
.entry .title:before {
    top: 10px;
    color: #343a40;
    right: auto;
    left: 1px;
}

@media (min-width: 267px) {
    .entry .title:before {
        right: auto;
        left: 2px;
    }
}
@media (min-width: 347px) {
    .entry .title:before {
        right: auto;
        left: 2.5px;
    }
}
@media (min-width: 387px) {
    .entry .title:before {
        right: auto;
        left: 3px;
    }
}
@media (min-width: 508px) {
    .entry .title:before {
        right: auto;
        left: 4px;
    }
}
@media (min-width: 768px) {
    .entry .title:before {
        right: -8px;
        left: auto;
    }
}
@media (min-width: 992px) {
    .entry .title:before {
        right: -6px;
        left: auto;
    }
}
@media (min-width: 1200px) {
    .entry .title:before {
        right: -4px;
        left: auto;
    }
}

@media (max-width: 768px) {
    .timeline-item-dateinner {
        text-align: left;
    }
    .entry .title {
        width: 90%;
    }
}
.hall-of-fame h2 {
    font-size: 1.75rem;
    line-height: 2rem;
    text-transform: uppercase;
}
.hall-of-fame hr {
    margin-top: 0.25rem;
    margin-bottom: 0;
    border-top: 3px solid #c7b184;
}
.trophies-section {
    background-color: #f8f9fa;
}
.trophy-wrapper {
    width: 100%;
}
:root {
    --swiper-theme-color: #A55858;
    --swiper-navigation-color: #c7b184;
}
.trophy {
    position: relative;
    height: 20rem;
    background: #fff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px; 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
}
.trophy-img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.trophy .trophy-content {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 2rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 2rem;
    border-radius: 4px;
    width: 80%;
    z-index: 1000;
}
.trophy .trophy-content h4 {
    margin-bottom: 0;
}
.trophy .trophy-content .btn {
    width: 100%;
}
.trophy .icon, .trophy-modal .icon {
    font-size: 16rem;
    color: rgba(0, 0, 0, 0.5);
}
.trophy img, .trophy-modal img {
    height: 17rem;
}
.trophy-modal p {
    margin-top: 1.25rem;
    font-size: 1.4rem;
}
.trophy .placeholder, .trophy-modal .placeholder {
    -webkit-filter: opacity(0.2);
            filter: opacity(0.2);
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    padding-top: 1rem;
    padding-bottom: calc(24px + 1rem);
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-slide img {
    display: block;
    object-fit: cover;
}
.facilities h1 {
    font-size: 3rem;
}
.facilities-content .section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2rem;
}
.facilities-content .details-title {
    font-size: 1rem;
}
.facilities-content .details-value {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.facilities-content hr {
    margin-top: 0.25rem;
    border-top: 3px solid #c7b184;
}
.facilities-content p {
    text-align: justify;
}
.facilities-content {
    line-height: 1.6;
}
.playerCard {
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px; 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    transition: visibility 0.3s ease;
}
.filterList {
    margin-top: 1rem;
}
.filterList .filterListItem {
    transition: opacity 1s ease-out;
}
.playerCard a {
    color: #212529 !important;
    text-decoration: none;
}
.playerCard a:hover {
    color: #212529 !important;
    text-decoration: none;
}
.playerCard:hover { 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%);
    cursor: pointer;
}
.playerCard:hover .playerNumber {
    color: #564A31;
}
.playerNumber {
    position: absolute;
    top: 1rem;
    left: calc(1rem + 15px);
    color: #a38c69;
    font-size: 3.5rem;
    font-weight: 900;
    text-shadow: 0px 2px 3px rgb(50 50 93 / 24%);
    width: 4rem;
}
.playerNumber span {
    display: block;
    text-align: center;
}
.playerImage {
    overflow: hidden;
    margin-top: 1rem;
}
.playerImage img {
    object-fit: cover;
    object-position: top;
    height: 13rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}
.playerCard:hover .playerImage img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.playerCard .slaviaLogo {
    position: absolute;
    top: 1rem;
    right: calc(1rem + 15px);
    width: 72px;
    height: 72px;
}
.playerCard .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.playerCardDetails {
    padding: 0.5rem 0.25rem;
    padding-bottom: 1rem;
    background-color: rgb(250 250 250);
}
.playerCardSurname {
    color: #a38c69;
    font-weight: 700;
    text-transform: uppercase;
}
.playerCardPosition {
    text-transform: uppercase;
    font-weight: 500;
}
.playerCardInfoRight {
    line-height: 2;
}
.playerStat {
    font-weight: 600;
    padding-left: 0 !important;
}
.slavia-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #e5e0da;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 0.5rem;
    border-radius: 6px;
}
.player-details {
    margin-top: 2rem;
}
.player-details-content {
    margin-top: 1rem;
    word-wrap: break-word;
}
.player-header .details {
    position: absolute;
    top: 0;
    margin-left: 5rem;
    height: 100%;
    width: 50%;
    z-index: 0;
}
.player-header .details .first-name {
    font-size: 2.25rem;
    margin-top: 1rem;
    z-index: 10;
}
.player-header .details .last-name {
    position: absolute;
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 10;
}
.player-header .details .number {
    position: absolute;
    bottom: 1rem;
    right: -0rem;
    font-size: 15rem;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: 900;
    font-stretch: extra-expanded;
    line-height: 15rem;
    color: #a38c69;
    opacity: 0.7;
    z-index: 1;
}
.player-header .details .position {
    font-size: 1.75rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #A55858;
    position: absolute;
    bottom: 3rem;
    z-index: 10;
}
.player-header .player-image {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    height: 400px;
    margin-top: -5rem;
    margin-right: 5rem;
    z-index: 0;
}
.player-header .header-background {
    overflow: hidden;
    height: calc(400px - 5rem);
    margin-left: -15px;
    margin-right: -15px;
    z-index: -10;
}
.player-header .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-filter: brightness(150%) opacity(11%) contrast(150%) grayscale(100%);
            filter: brightness(150%) opacity(11%) contrast(150%) grayscale(100%);
}
.player-details {
    margin-bottom: 2rem;
}
.player-details .section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2rem;
}
.player-details .details-title {
    font-size: 1rem;
}
.player-details .details-value {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.player-details hr {
    margin-top: 0.25rem;
    border-top: 3px solid #c7b184;
}
.player-details .about-me {
    line-height: 1.6rem;
    text-align: justify;
}
.vertical-space {
    height: 1.25rem;
}
.vertical-space-lg {
    height: 0.5rem;
}

.quote-icon {
    position: absolute;
  font-family: 'FontAwesome';
  top: 0;
  content: "\0022";
  font-size: 200px;
  color: rgba(0,0,0,0.1);
}
.blockquote {
    padding: 60px 80px 40px;
    position: relative;
}
.blockquote p {
    font-family: "Utopia-italic";
    font-size: 35px;
    font-weight: 700px;
    text-align: center;
}
.blockquote::after {
    content: "";
    top: 20px;
    left: 50%;
    margin-left: -100px;
    position: absolute;
    border-bottom: 3px solid #d99f75;
    height: 3px;
    width: 200px;
}

@media (max-width: 1200px) {
    .player-header .details {
        width: 52%;
    }
    .player-header .details .number {
        bottom: 1.5rem;
        font-size: 12.5rem;
        line-height: 12.5rem;
    }
}
@media (max-width: 992px) {
    .player-header .header-background {
        height: calc(350px - 5rem);
    }
    .player-header .details {
        width: 50%;
        margin-left: 3rem;
    }
    .player-header .player-image {
        height: 350px;
        margin-right: 3rem;
    }
    .player-header .details .first-name {
        font-size: 2rem;
    }
    .player-header .details .last-name {
        font-size: 2.75rem;
        line-height: 2.75rem;
    }
    .player-header .details .number {
        top: 0rem;
        font-size: 12.5rem;
        line-height: 12.5rem;
    }
    .player-header .details .position {
        font-size: 1.5rem;
        line-height: 1.75rem;
        bottom: 2.5rem;
    }
}
@media (max-width: 768px) {
    .player-header .header-background {
        height: calc(300px - 5rem);
    }
    .player-header .details {
        width: 55%;
        margin-left: 0.5rem;
    }
    .player-header .player-image {
        height: 300px;
        margin-right: 0;
    }
    .player-header .details .first-name {
        font-size: 1.6rem;
    }
    .player-header .details .last-name {
        font-size: 2.15rem;
        line-height: 2.15rem;
    }
    .player-header .details .number {
        top: 0rem;
        font-size: 10rem;
        line-height: 10rem;
    }
    .player-header .details .position {
        font-size: 1.15rem;
        line-height: 1.75rem;
        bottom: 1.75rem;
    }
    .blockquote {
        padding: 0;
        padding-top: 60px;
    }
}
@media (max-width: 600px) {
    .player-header .header-background {
        height: calc(300px - 5rem);
    }
    .player-header .details {
        width: 100%;
        margin-left: 0;
        height: calc(180px - 5rem);
        left: 0;
        bottom: 0;
        top: auto;
        padding-left: 1rem;
        background-color:rgba(0, 0, 0, 0.6);
        color: white;
    }
    .player-header .player-image {
        height: 300px;
        margin-right: -1rem;
    }
    .player-header .details .first-name {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-top: 0.25rem;
    }
    .player-header .details .last-name {
        font-size: 2.15rem;
        line-height: 2.15rem;
    }
    .player-header .details .number {
        top: -120px;
        left: 1rem;
        right: auto;
        font-size: 7rem;
        line-height: 7rem;
    }
    .player-header .details .position {
        font-size: 1.15rem;
        line-height: 1.75rem;
        bottom: 0.3rem;
        color: #d99f75;
    }
}
.calendar {
    margin-bottom: 2rem;
}
.calendar-filter {
    margin-top: 1rem;
}
.slavia-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #e5e0da;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 0.5rem;
    border-radius: 6px;
}

.tournaments {
    margin-top: 1rem;
}

.table {
    margin-bottom: 2rem !important;
}
.table thead {
    background-color: #343a40;
    color: white;
    font-size: 1rem;
    font-weight: 400 !important;
}
.table th {
    padding: 0.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.table td {
    padding: 0 !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.table a {
    color: #212529;
}
.table a div {
    width: 100%;
    padding-bottom: .25rem;
    padding-top: .25rem;
}
.no-results {
    margin-top: 2rem;
}
.phase {
    margin-top: 1rem;
}
.phase h4 {
    margin-bottom: 2rem;
}
.match {
    padding: 0.5rem 0 !important;
}
.match .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.match .team {
    font-size: 1.25rem;
    font-weight: 600;
    display: inline-block;
}
.match .result {
    padding: 0 1.5rem;
    font-size: 1.25rem;
}
.match .game {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.match .result .res {
    border: 1px solid #6c757d;
    padding: 0 0.5rem;
    width: 2rem;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    font-weight: 700;
}
.match .bigger-res {
    font-weight: 500;
}
.match .result .bigger-res {
    color: red;
}
.btn-stats {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}
.stats-col {
    justify-content: center !important;
}
.match .team {
    width: calc((100% - 8rem) / 2);
}
.match .time-remaining {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
}
.match .res-right {
    text-align: right;
}
.match .res-left {
    text-align: left;
}

@media (min-width: 992px) {
    .match .container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .stats-col {
        justify-content: flex-end !important;
    }
}

@media (max-width: 991px) {
    .round td {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

@media (max-width: 767px) {
    .match-location {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .match-date-col {
        margin-bottom: 0.75rem !important;
    }
    .match-location-col {
        justify-content: flex-end !important;
        margin-bottom: 0.75rem !important;
    }
    .match .result {
        padding: 0 1rem;
    }
    .match .team {
        width: calc((100% - 7rem) / 2);
        padding-left: 5px;
        padding-right: 5px;
    }
    .stats-col {
        margin-top: 0.75rem !important;
    }
    .match .time-remaining {
        width: 7rem;
    }
    .match .team {
        font-size: 1rem;
    }
}
.sponsors {
    margin-top: 3rem;
}
.sponsors:last-child {
    margin-bottom: 5rem;
}
.sponsors a {
    color: #000;
    color: initial;
}
.sponsors a:hover {
    text-decoration: none !important;
    color: #000;
}
.sponsors a:hover .title span {
    text-decoration: underline !important;
}
.sponsors a:hover .title b {
    padding-left: 0.85rem;
}
.sponsor {
    height: 16.25rem;
    margin-bottom: 2rem;
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px; 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
}
.sponsor .image {
    position: relative;
    height: 12rem;
    width: 100%;
}
.sponsor img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%;
    height: 10rem;
    object-fit: scale-down;
}
.sponsor .title {
    height: 4.25rem;
    padding-left: 1.15rem;
    padding-right: 1.15rem;
}
.sponsor .title h4 {
    margin-bottom: 0.1rem;
}
.sponsor .title span {
    color: #0056b3;
}
.sponsor .title b {
    color: #0056b3;
    padding-left: 0.5rem;
    font-size: 1.15rem;
    transition: all 0.25s ease;
}
.article-back {
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.article-full .text-muted {
    display: block;
    margin-bottom: 1.75rem;
    font-size: 1.1rem;
}
.article-full h1 {
    margin-bottom: 0.5rem !important;
    font-size: 2.25rem !important;
}
.social {
    margin: auto;
    margin-bottom: 2rem;
    width: 48px;
}
.social > *:hover {
    opacity: 0.5;
}
.social::before {
    content: '';
    height: 2px;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    position: absolute;
    bottom: calc(2rem + 24px);
    left: 0;
    background-color: #e6e6e6;
    z-index: -1;
}

.article-content {
    font-size: 1.125rem;
    line-height: 1.6;
}
.article-content p {
    margin-bottom: 1.4rem;
}

@media (min-width: 768px) {
    .article-full h1 {
        font-size: 3rem !important;
    }
}
@media (min-width: 992px) {
    .article-full {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
    .article-back {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
    .article-full h1 {
        font-size: 3.25rem !important;
    }
    .article-full p {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
@media (min-width: 1200px) {
    .article-full h1 {
        font-size: 4rem !important;
    }
}
.news {
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.search {
    margin-bottom: 3rem;
}
.search-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: calc(1em + 2px);
    height: calc(1em + 2px);
    margin-top: 0.625rem;
    margin-right: 0.75rem;
    cursor: pointer;
}
#footer {
    position: relative;
    background-color: #343a40;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
}
#footer a {
    color: rgba(255, 255, 255, 0.5);
}
#footer a:hover {
    color: rgba(255, 255, 255, 0.75);
}
#footer .footer-logo {
    height: 144px;
}
#footer small {
    color: rgba(255, 255, 255, 0.9);
}
#footer hr {
    border-top-color: rgba(255, 255, 255, 0.5);
}
#footer h5 {
    color: rgba(255, 255, 255, 1);
}
#footer h6 {
    color: rgba(255, 255, 255, 0.75);
}
#footer .icon {
    margin-right: 1.25rem;
}
.coachCard {
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px; 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    transition: visibility 0.3s ease;
}
.coachCard a {
    color: #212529 !important;
    text-decoration: none;
}
.coachCard a:hover {
    color: #212529 !important;
    text-decoration: none;
}
.coachCard:hover { 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%);
    cursor: pointer;
}
.coachImage {
    overflow: hidden;
    margin-top: 1rem;
}
.coachImage img {
    object-fit: cover;
    object-position: top;
    height: 13rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
.coachCard .slaviaLogo {
    position: absolute;
    top: 1rem;
    right: calc(1rem + 15px);
    width: 72px;
    height: 72px;
}
.coachCard .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.coachCardDetails {
    padding: 0.5rem 0.25rem;
    padding-bottom: 1rem;
    background-color: rgb(250 250 250);
}
.coachCardSurname {
    color: #a38c69;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
}
.coachCardPosition {
    text-transform: uppercase;
    font-weight: 500;
}
.coach-details-content {
    margin-top: 1rem;
    word-wrap: break-word;
}
.coach-details .slaviaLogo {
    position: absolute;
    top: 10px;
    right: 0;
    right: calc(1rem + 15px);
    width: 94px;
    height: 94px;
    z-index: 1000;
}
.coach-details .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.coach-header .details {
    position: absolute;
    top: 0;
    margin-left: 5rem;
    height: 100%;
    width: 50%;
    z-index: 0;
}
.first-name {
    font-size: 2.25rem;
}
.last-name {
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
}
.position {
    font-size: 1.75rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #A55858;
}
.coach-image {
    height: 400px;
    border: 1px solid #aaa;
    background-color: #f9f9f9;
    object-fit: cover;
    object-position: top;
}
.coach-details {
    margin-bottom: 2rem;
}
.coach-details .section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2rem;
}
.coach-details .details-title {
    font-size: 1rem;
}
.coach-details .details-value {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.coach-details hr {
    margin-top: 0.25rem;
    border-top: 3px solid #c7b184;
}
.vertical-space {
    height: 1.25rem;
}
.vertical-space-lg {
    height: 0.5rem;
}

@media (max-width: 992px) {
    .coach-image {
        height: 350px;
        margin-right: 3rem;
    }
}
@media (max-width: 768px) {
    .coach-image {
        height: 300px;
        margin-right: 0;
    }
}
@media (max-width: 600px) {
    .coach-image {
        height: 300px;
        margin-right: -1rem;
    }
    .first-name {
        font-size: 2rem;
        line-height: 2rem;
        margin-top: 0.25rem;
    }
    .last-name {
        font-size: 2.75rem;
        line-height: 2.75rem;
    }
    .position {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

@media (min-width: 576px) {
    .coach-details .slaviaLogo {
        width: 94px;
        height: 94px;
    }
}
@media (min-width: 768px) {
    .coach-details .slaviaLogo {
        width: 94px;
        height: 94px;
        top: -2rem;
    }
}
@media (min-width: 992px) {
    .coach-details .slaviaLogo {
        width: 128px;
        height: 128px;
    }
}
@media (min-width: 1200px) {
    .coach-details .slaviaLogo {
        width: 148px;
        height: 148px;
    }
}
.presidentCard {
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px; 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    transition: visibility 0.3s ease;
}
.presidentCard:hover { 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
    cursor: auto;
}
.presidentImage {
    overflow: hidden;
    padding-top: 1rem;
    background-color: #fcfdfc;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.presidentImage img {
    object-fit: cover;
    object-position: top;
    height: 13rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}
.presidentCard .slaviaLogo {
    position: absolute;
    top: 1rem;
    right: calc(1rem + 15px);
    width: 72px;
    height: 72px;
}
.presidentCard .slaviaLogo img {
    width: 100%;
    height: 100%;
}
.presidentCardDetails {
    padding: 0.5rem 0.25rem;
    padding-bottom: 1rem;
    background-color: #343a40 !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.presidentCardSurname {
    color: #c8bba6;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
}
.presidentCardPosition {
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
}
