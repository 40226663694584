#footer {
    position: relative;
    background-color: #343a40;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
}
#footer a {
    color: rgba(255, 255, 255, 0.5);
}
#footer a:hover {
    color: rgba(255, 255, 255, 0.75);
}
#footer .footer-logo {
    height: 144px;
}
#footer small {
    color: rgba(255, 255, 255, 0.9);
}
#footer hr {
    border-top-color: rgba(255, 255, 255, 0.5);
}
#footer h5 {
    color: rgba(255, 255, 255, 1);
}
#footer h6 {
    color: rgba(255, 255, 255, 0.75);
}
#footer .icon {
    margin-right: 1.25rem;
}