.article {
    width: 100%;
    margin-bottom: 3.5rem;
    border: 1px solid #e9ecef;
    margin-bottom: 2rem;
    padding: 0 !important;
    border-radius: 4px;
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 11%);
}
.article a, .article a:hover {
    color: inherit;
    text-decoration: none;
}
.article:hover {
    -webkit-box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%); 
    box-shadow: 0px 3px 10px 2px rgb(50 50 93 / 22%);
    cursor: pointer;
}
.article:hover img {
    filter: opacity(0.9);
}
.article .image img {
    width: 100%;
    height: 12rem;
    border-radius: 4px 4px 0 0;
    object-fit: cover;
    object-position: top;
}
.article .image, .article .image img {
    width: 100%;
}
.article .underline {
    height: 3px;
    width: 100%;
    background: linear-gradient(to right, #343a40 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .25s ease-out;
}
.article:hover .underline {
    background-position: left bottom;
}
.article .body {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.article h4 {
    margin-top: 0.25rem;
    font-weight: 700;
}
.article .text-muted {
    font-size: 0.9rem;
    display: block;
    margin-top: -0.2rem;
}
.article p {
    display: block;
    margin-top: 0.25rem;
    padding-left: 0.5rem;
}