.facilities h1 {
    font-size: 3rem;
}
.facilities-content .section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2rem;
}
.facilities-content .details-title {
    font-size: 1rem;
}
.facilities-content .details-value {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.facilities-content hr {
    margin-top: 0.25rem;
    border-top: 3px solid #c7b184;
}
.facilities-content p {
    text-align: justify;
}
.facilities-content {
    line-height: 1.6;
}