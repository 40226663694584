.slavia-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #e5e0da;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 0.5rem;
    border-radius: 6px;
}
.player-details {
    margin-top: 2rem;
}
.player-details-content {
    margin-top: 1rem;
    word-wrap: break-word;
}
.player-header .details {
    position: absolute;
    top: 0;
    margin-left: 5rem;
    height: 100%;
    width: 50%;
    z-index: 0;
}
.player-header .details .first-name {
    font-size: 2.25rem;
    margin-top: 1rem;
    z-index: 10;
}
.player-header .details .last-name {
    position: absolute;
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 10;
}
.player-header .details .number {
    position: absolute;
    bottom: 1rem;
    right: -0rem;
    font-size: 15rem;
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: 900;
    font-stretch: extra-expanded;
    line-height: 15rem;
    color: #a38c69;
    opacity: 0.7;
    z-index: 1;
}
.player-header .details .position {
    font-size: 1.75rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #A55858;
    position: absolute;
    bottom: 3rem;
    z-index: 10;
}
.player-header .player-image {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    height: 400px;
    margin-top: -5rem;
    margin-right: 5rem;
    z-index: 0;
}
.player-header .header-background {
    overflow: hidden;
    height: calc(400px - 5rem);
    margin-left: -15px;
    margin-right: -15px;
    z-index: -10;
}
.player-header .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(150%) opacity(11%) contrast(150%) grayscale(100%);
}
.player-details {
    margin-bottom: 2rem;
}
.player-details .section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 2rem;
}
.player-details .details-title {
    font-size: 1rem;
}
.player-details .details-value {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
}
.player-details hr {
    margin-top: 0.25rem;
    border-top: 3px solid #c7b184;
}
.player-details .about-me {
    line-height: 1.6rem;
    text-align: justify;
}
.vertical-space {
    height: 1.25rem;
}
.vertical-space-lg {
    height: 0.5rem;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);

.quote-icon {
    position: absolute;
  font-family: 'FontAwesome';
  top: 0;
  content: "\0022";
  font-size: 200px;
  color: rgba(0,0,0,0.1);
}
.blockquote {
    padding: 60px 80px 40px;
    position: relative;
}
.blockquote p {
    font-family: "Utopia-italic";
    font-size: 35px;
    font-weight: 700px;
    text-align: center;
}
.blockquote::after {
    content: "";
    top: 20px;
    left: 50%;
    margin-left: -100px;
    position: absolute;
    border-bottom: 3px solid #d99f75;
    height: 3px;
    width: 200px;
}

@media (max-width: 1200px) {
    .player-header .details {
        width: 52%;
    }
    .player-header .details .number {
        bottom: 1.5rem;
        font-size: 12.5rem;
        line-height: 12.5rem;
    }
}
@media (max-width: 992px) {
    .player-header .header-background {
        height: calc(350px - 5rem);
    }
    .player-header .details {
        width: 50%;
        margin-left: 3rem;
    }
    .player-header .player-image {
        height: 350px;
        margin-right: 3rem;
    }
    .player-header .details .first-name {
        font-size: 2rem;
    }
    .player-header .details .last-name {
        font-size: 2.75rem;
        line-height: 2.75rem;
    }
    .player-header .details .number {
        top: 0rem;
        font-size: 12.5rem;
        line-height: 12.5rem;
    }
    .player-header .details .position {
        font-size: 1.5rem;
        line-height: 1.75rem;
        bottom: 2.5rem;
    }
}
@media (max-width: 768px) {
    .player-header .header-background {
        height: calc(300px - 5rem);
    }
    .player-header .details {
        width: 55%;
        margin-left: 0.5rem;
    }
    .player-header .player-image {
        height: 300px;
        margin-right: 0;
    }
    .player-header .details .first-name {
        font-size: 1.6rem;
    }
    .player-header .details .last-name {
        font-size: 2.15rem;
        line-height: 2.15rem;
    }
    .player-header .details .number {
        top: 0rem;
        font-size: 10rem;
        line-height: 10rem;
    }
    .player-header .details .position {
        font-size: 1.15rem;
        line-height: 1.75rem;
        bottom: 1.75rem;
    }
    .blockquote {
        padding: 0;
        padding-top: 60px;
    }
}
@media (max-width: 600px) {
    .player-header .header-background {
        height: calc(300px - 5rem);
    }
    .player-header .details {
        width: 100%;
        margin-left: 0;
        height: calc(180px - 5rem);
        left: 0;
        bottom: 0;
        top: auto;
        padding-left: 1rem;
        background-color:rgba(0, 0, 0, 0.6);
        color: white;
    }
    .player-header .player-image {
        height: 300px;
        margin-right: -1rem;
    }
    .player-header .details .first-name {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-top: 0.25rem;
    }
    .player-header .details .last-name {
        font-size: 2.15rem;
        line-height: 2.15rem;
    }
    .player-header .details .number {
        top: -120px;
        left: 1rem;
        right: auto;
        font-size: 7rem;
        line-height: 7rem;
    }
    .player-header .details .position {
        font-size: 1.15rem;
        line-height: 1.75rem;
        bottom: 0.3rem;
        color: #d99f75;
    }
}