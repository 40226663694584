.news {
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.search {
    margin-bottom: 3rem;
}
.search-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: calc(1em + 2px);
    height: calc(1em + 2px);
    margin-top: 0.625rem;
    margin-right: 0.75rem;
    cursor: pointer;
}